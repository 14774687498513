import React from "react"
import { Button } from "../Button"
// import AboutDescriptionItem from "./AboutDescriptionItem"

const AboutDescription = () => {
  return (
    <section className="max-w-screen-xl mx-auto py-24">
      {/* <h2
        className="text-center uppercase  font-bold sm:text-4xl"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        Czego Nauczamy
      </h2>
      <div className="flex flex-wrap mt-12">
        <AboutDescriptionItem />
        <AboutDescriptionItem />
        <AboutDescriptionItem />
        <AboutDescriptionItem />
        <AboutDescriptionItem />
        <AboutDescriptionItem />
      </div> */}
      <h4
        className="text-center uppercase font-bold sm:text-4xl"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        Wyznanie wiary do pobrania
      </h4>

      <div className="flex mt-12" data-aos="fade-up" data-aos-duration="1000">
        <Button
          to="https://baptysci.pl/naszawiara/wyznaniewiary"
          target="_blank"
          className="px-6 py-3 text-sm mx-auto font-medium text-dark rounded-lg"
        >
          Wyznanie wiary
        </Button>
      </div>
    </section>
  )
}

export default AboutDescription
