import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const StyledDescriptionWrapper = styled.div`
  background-color: #ebebeb;
  width: 90%;
  margin-left: auto;
  padding: 2rem;
  position: relative;
  top: -7rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    width: 90%;
    top: -2rem;
    padding: 1.5rem;
  }
`

const Element = ({ title, html, image }) => {
  return (
    <div className="flex flex-col" data-aos="fade-up" data-aos-duration="1000">
      <Img
        className="w-90 h-72"
        fluid={image.childImageSharp.fluid}
        alt={title}
      />
      <StyledDescriptionWrapper>
        <h3 className="font-bold">{title}</h3>

        <div className="mt-4" dangerouslySetInnerHTML={{ __html: html }}></div>
      </StyledDescriptionWrapper>
    </div>
  )
}

export default Element
