import React from "react"
import { AiOutlineAim, AiFillHeart } from "react-icons/ai"
import PlaneSVG from "../../assets/shapes/plane.svg"
import styled from "styled-components"

const StyledPlane = styled(PlaneSVG)`
  width: 5rem;
  height: 5rem;
`

const StyledAim = styled(AiOutlineAim)`
  width: 5rem;
  height: 5rem;

  & path:last-child {
    fill: #c71f37;
  }
`

const StyledHearth = styled(AiFillHeart)`
  width: 5rem;
  height: 5rem;
  fill: #c71f37;
`

const Ideas = () => {
  return (
    <section
      className="container mt-24"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="max-w-screen-xl mx-auto grid gap-5 lg:grid-cols-3">
        <div className="flex flex-col items-center">
          <StyledPlane />
          <h3 className="font-bold text-2xl mt-4">WIZJA</h3>
          <p className="mt-4">Kościół jako organizm</p>
          <p>Kościół jako duchowa rodzina</p>
          <p>Kościół, którego jestem cześcią</p>
          <p className="font-bold text-lg mt-4">"DĄŻĄC DO OSIĄGNIĘCIA WIZJI"</p>
        </div>
        <div className="flex flex-col items-center">
          <StyledAim />
          <h3 className="font-bold text-2xl mt-4">MISJA</h3>
          <p className="mt-4">Głosić Jezusa Chrystusa</p>
          <p>Czynić innych Jego uczniami</p>
          <p>Być razem</p>
          <p className="font-bold text-lg mt-4">"PODEJMUJEMY MISJĘ"</p>
        </div>
        <div className="flex flex-col items-center">
          <StyledHearth />
          <h3 className="font-bold text-2xl mt-4">WARTOŚCI</h3>
          <p className="mt-4">Miłość wzajemna</p>
          <p>Troska o drugiego</p>
          <p>Więź z Kościołem</p>
          <p className="font-bold text-lg mt-4">"OPARTĄ O BIBLIJNE WARTOŚCI"</p>
        </div>
      </div>
    </section>
  )
}

export default Ideas
