import React from "react"
import AboutDescription from "../components/About/AboutDescription"
import AboutHero from "../components/About/AboutHero"
import Elements from "../components/About/Elements"
import Ideas from "../components/About/Ideas"
import PageTemplate from "../templates/PageTemplate"
import Helmet from "react-helmet"

const About = () => {
  return (
    <PageTemplate>
      <Helmet title="Moja oaza - O nas" />
      <AboutHero />
      <Ideas />
      <Elements />
      <AboutDescription />
    </PageTemplate>
  )
}

export default About
