import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Element from "./Element"

const Elements = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    {
      allMarkdownRemark(sort: { fields: frontmatter___order }) {
        nodes {
          frontmatter {
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
    }
  `)

  return (
    <section className="container  pt-24">
      <h2
        className="text-center uppercase font-bold text-2xl sm:text-4xl"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        Kluczowe elementy kościoła
      </h2>

      <div className="max-w-screen-xl mx-auto grid gap-7 grid-cols-1 lg:grid-cols-3 mt-20">
        {allMarkdownRemark.nodes.map(({ frontmatter, html }) => (
          <Element
            title={frontmatter.title}
            html={html}
            image={frontmatter.featuredImage}
          />
        ))}
      </div>
    </section>
  )
}

export default Elements
