import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Trapezoid from "../../assets/shapes/trapezoid.svg"

const StyledHero = styled.section`
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${({ image }) => image});
  background-position: center;
  background-size: cover;
`

const StyledTrapezoid = styled(Trapezoid)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

const AboutHero = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulMainPage {
        background {
          file {
            url
          }
        }
      }
    }
  `)

  return (
    <StyledHero image={data.contentfulMainPage.background.file.url}>
      <div className="max-w-screen-xl py-24 mx-auto lg:h-full lg:items-center lg:flex">
        <div className="mx-auto text-center py-24 sm:p-24 relative">
          <StyledTrapezoid
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-delay="1500"
          />
          <p
            className="sm:leading-relaxed text-white font-bold text-2xl sm:text-5xl"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Mój dom
          </p>
          <p
            className="sm:leading-relaxed text-white  font-bold text-2xl sm:text-5xl"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            Moja duchowa rodzina
          </p>
          <p
            className="sm:leading-relaxed text-white  font-bold text-2xl sm:text-5xl"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="1000"
          >
            Moja oaza
          </p>
        </div>
      </div>
    </StyledHero>
  )
}

export default AboutHero
